<template>
  <section class="c-steps container">
    <h2 class="c-steps__title headline-2">
      Начать работать в ДомИнтернет ― просто
    </h2>
    <div class="c-steps__cards d-flex justify-between">
      <div class="c-steps__card relative">
        <img
          loading="lazy"
          src="@/assets/images/useful/step1.svg"
          alt="step1"
        >
        <p class="title-5">
          <span
            class="secondary-dark--text pointer"
            @click="scrollToForm"
          >Заполни анкету</span> на сайте
        </p>
        <span class="gray-darker--text">1</span>
      </div>
      <div class="c-steps__card relative">
        <img
          loading="lazy"
          src="@/assets/images/useful/step2.svg"
          alt="step2"
        >
        <p class="title-5">
          Посети онлайн-встречу
        </p>
        <span class="gray-darker--text">2</span>
      </div>
      <div class="c-steps__card relative">
        <img
          loading="lazy"
          src="@/assets/images/useful/step3.svg"
          alt="step3"
        >
        <p class="title-5">
          Пройди обучение с тренером
        </p>
        <span class="gray-darker--text">3</span>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import { scrollToBlock } from '~/composible/scrollToBlock'

const scrollToForm = () => {
  const target = document.getElementById('c-form')
  scrollToBlock(target, 750, 0)
}
</script>

<style scoped lang="scss">
.c-steps {
  margin-top: 64px;
  margin-bottom: 60px;
  @media (max-width: getBreakpoint(tablet)) {
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 16px;
    @media (max-width: getBreakpoint(tablet)) {
      margin-bottom: 12px;
    }
  }

  &__cards {
    @media (max-width: getBreakpoint(tablet)) {
      flex-direction: column;
    }
  }

  &__card {
    border-radius: 16px;
    border: 1px solid color(gray-darker);
    background: color(white);
    width: calc(33.33% - 8px);
    padding: 24px;
    overflow: hidden;
    @media (max-width: getBreakpoint(tablet)) {
      width: 100%;
      padding: 16px;
      margin-bottom: 8px;
    }
    img {
      margin-bottom: 16px;
      @media (max-width: getBreakpoint(tablet)) {
        margin-bottom: 4px;
      }
    }
    .pointer {
      transition: color .24s;
      &:hover {
        color: color(primary);
      }
    }

    &>span {
      position: absolute;
      right: -10px;
      top: -15px;
      font-size: 192px;
      line-height: 1;
      font-weight: 700;
      direction: rtl;
      @media (max-width: getBreakpoint(tablet)) {
        font-size: 156px;
      }
    }
  }
}
</style>
